import printJS from "print-js";
import { export_json_to_excel } from "@/utils/excel/Export2Excel.js";
/**
 * @description: 找到数组a中满足数组b某个条件的所有数据
 * defaultData 默认菜单
 * authData可以访问的菜单权限
 * key 键名
 */
export function getSameTokenData(defaultData, authData, key) {
  let lastData = [];
  let lastAuthData = [];
  if (key) {
    authData.length &&
      authData.forEach((el) => {
        lastAuthData.push(el[key]);
      });
  } else {
    lastAuthData = authData;
  }
  defaultData.length &&
    defaultData.forEach((item) => {
      const index = lastAuthData.findIndex((el) => item["token"] === el);
      if (index != -1) {
        lastData.push(item);
      }
    });
  return lastData;
}
/**
 * @description: 找到数组中满足条件的某一项
 * data 数组
 * token 找寻的值
 * key 指定的键名
 */
export function getDataByToken(data, token, key) {
  const lastKey = key || "token";
  const lastData = data.find((item) => item[lastKey] === token) || [];
  return lastData;
}
/**
 * @description: 返回可以查看的表格列
 */
export function getBtnAuth(data, btns) {
  let lastAuthBtn = [];
  if (btns.length) {
    lastAuthBtn = getSameTokenData(btns, data, "api_menu_token") || {};
  }
  return lastAuthBtn;
}
/**
 * @description: 返回表格顶部可以操作的按钮
 */
export function getTopBtnAuth(data, btns) {
  let lastAuthBtn = [];
  if (data.length && btns.length) {
    lastAuthBtn = getSameTokenData(btns, data, "api_menu_token") || {};
  }
  return lastAuthBtn;
}
/**
 * @description: 处理导出数据
 */
export function exportExcel(title, tableData, propertiesConfig, label, value) {
  let label_ = label || "displayName";
  let value_ = value || "field";
  let header = [];
  let datas = [];
  propertiesConfig.length &&
    propertiesConfig.forEach((el) => {
      header.push(el[label_]);
    });

  tableData.length &&
    tableData.forEach((item) => {
      let arr = [];
      propertiesConfig.forEach((el) => {
        arr.push(item[el[value_]]);
      });

      datas.push(arr);
    });

  export_json_to_excel(header, datas, title);
}

/**
 * @description: 打印
 */
export function print(titile, tableData, propertiesConfig) {
  const style = "@page {margin:0 10mm};  color:pink";
  printJS({
    printable: tableData,
    properties: propertiesConfig,
    type: "json",
    headerStyle: "font-size:2px;font-weight:600;padding:15px 0 10px 0;", //标题设置
    gridHeaderStyle:
      "font-size:6px;font-weight:400;border: 1px solid #ccc;padding:5px;text-align:center;",
    gridStyle:
      "font-size:1px;font-weight:200;border: 1px solid #ccc;padding:3px;text-align:center;",
    style,
    header: titile,
  });
}

/**
 * @description: 检测字符串
 */
export function checkStr(str, type) {
  // 常用正则验证，注意type大小写
  switch (type) {
    case "phone": // 手机号码
      return /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(str);
    case "tel": // 座机
      return /^(0\d{2,3}-\d{7,8})(-\d{1,4})?$/.test(str);
    case "card": // 身份证
      return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(str);
    case "pwd": // 密码以字母开头，长度在6~18之间，只能包含字母、数字和下划线
      return /^[a-zA-Z]\w{5,17}$/.test(str);
    case "pwd2": // 密码以大小写字母、数字
      return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(str);
    case "postal": // 邮政编码
      return /[1-9]\d{5}(?!\d)/.test(str);
    case "QQ": // QQ号
      return /^[1-9][0-9]{4,9}$/.test(str);
    case "email": // 邮箱
      return /^[\w-]+(.[\w-]+)*@[\w-]+(.[\w-]+)+$/.test(str);
    case "money": // 金额(小数点2位)
      return /^\d*(?:.\d{0,2})?$/.test(str);
    case "IP": // IP
      return /((?:(?:25[0-5]|2[0-4]\d|[01]?\d?\d)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d?\d))/.test(
        str
      );
    case "date": // 日期时间
      return (
        /^(\d{4})-(\d{2})-(\d{2}) (\d{2})(?::\d{2}|:(\d{2}):(\d{2}))$/.test(
          str
        ) || /^(\d{4})-(\d{2})-(\d{2})$/.test(str)
      );
    case "number": // 数字
      return /^[0-9]$/.test(str);
    case "english": // 英文
      return /^[a-zA-Z]+$/.test(str);
    case "chinese": // 中文
      return /^[\u4E00-\u9FA5]+$/.test(str);
    case "lower": // 小写
      return /^[a-z]+$/.test(str);
    case "upper": // 大写
      return /^[A-Z]+$/.test(str);
    case "HTML": // HTML标记
      return /<("[^"]*"|'[^']*'|[^'">])*>/.test(str);
    case "letterNumber": // 大写
      return /^[A-Za-z0-9]+$/.test(str);
    default:
      return true;
  }
}
